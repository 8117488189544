import React, {useEffect, useState} from "react";
import "./App.less";
import {Layout} from "./layout/Layout";
import {queryClient} from "./config/queryClient";
import browserHistory from "./config/browserHistory";
import {QueryClientProvider} from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'
// Check https://github.com/remix-run/react-router/pull/7586
import {unstable_HistoryRouter as HistoryRouter} from "react-router-dom";
import {ErrorBoundary} from "@sentry/react";
import {ServerErrorPage} from "./pages/ServerErrorPage";
import {OrganisationInformationProvider} from "./layout/OrganisationInformationProvider";
import keycloak from "./config/keycloak";
import { KeycloakInitOptions } from "keycloak-js";

function App() {
    const [authChecked, setAuthChecked] = useState<boolean>(false);

    useEffect(() => {
        let tokens: any = localStorage.getItem('tokens');
        let kcInitOptions: KeycloakInitOptions = {
            checkLoginIframe: false,
        }; 
        if (tokens) {
            console.log("Initialised with tokens");
            tokens = JSON.parse(tokens);
            kcInitOptions = {
                ...kcInitOptions,
                ...tokens,
            }
        } else {
            kcInitOptions = {
                ...kcInitOptions,
                onLoad: 'login-required', 
            }
        }
        keycloak
            .init(kcInitOptions)
            .then(() => setAuthChecked(true));
    }, []);

    if (!authChecked) {
        return null;
    }

    return (
        <QueryClientProvider client={queryClient}>
            <HistoryRouter history={browserHistory}>
                <ErrorBoundary fallback={(props) => <ServerErrorPage {...props}/>}>
                    <OrganisationInformationProvider>
                        <Layout/>
                    </OrganisationInformationProvider>
                </ErrorBoundary>
            </HistoryRouter>
            <ReactQueryDevtools initialIsOpen={false} position='bottom-right'/>
        </QueryClientProvider>
    );
}

export default App;
