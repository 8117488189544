import Keycloak, { KeycloakTokenParsed, KeycloakPromise } from "keycloak-js";
import {setUser} from "@sentry/react";
import * as Sentry from '@sentry/browser';
import appConfig from "./config"

const keycloak = new Keycloak({
    url: appConfig.AUTH_ENDPOINT,
    realm: appConfig.AUTH_REALM!,
    clientId: appConfig.AUTH_CLIENT_ID!,
});

const getTokenRemainingValidity = (tokenParsed: KeycloakTokenParsed) => {
    if (!tokenParsed['exp']) {
        console.log('[KEYCLOAK] Unable to determine if token is expired as exp is not set');
        return -1;
    }

    const timeSkew = keycloak.timeSkew || 0;
    var expiresIn = tokenParsed['exp'] - Math.ceil(new Date().getTime() / 1000) + timeSkew;
    return expiresIn;
}

const isTokenValid = (tokenParsed: KeycloakTokenParsed, minValidity?: number) => {
    let remainingValidity = getTokenRemainingValidity(tokenParsed);
    if (minValidity) {
        remainingValidity -= minValidity;
    }
    return remainingValidity > 0;
}

const updateToken = keycloak.updateToken;
keycloak.updateToken = (minValidity: number) => {
    if (
        keycloak.refreshToken && 
        keycloak.refreshTokenParsed && 
        !isTokenValid(keycloak.refreshTokenParsed, 5)
    ) {
        keycloak.onAuthRefreshError && keycloak.onAuthRefreshError();
        // Safe to cast because KeycloakPromise is just a native Promise
        // with two additional methods which are deprecated in favour of the native .then() and .catch()
        // and they're no longer used anywhere in the keycloak-js repo
        return Promise.reject() as KeycloakPromise<any, any>;
    }
    return updateToken(minValidity);
}

let refreshTokenExpiredTimeoutId: null | NodeJS.Timeout = null;

keycloak.onAuthRefreshError = () => {
    localStorage.removeItem('tokens');
    keycloak.logout();
};

keycloak.onAuthRefreshSuccess = () => {
    if (refreshTokenExpiredTimeoutId) {
        clearTimeout(refreshTokenExpiredTimeoutId);
        refreshTokenExpiredTimeoutId =  null;
    }
    if (keycloak.refreshTokenParsed && keycloak.timeSkew !== null) {
        const expiresIn = Math.max(0, getTokenRemainingValidity(keycloak.refreshTokenParsed) - 5);
        console.log("Refresh remaining validity ", getTokenRemainingValidity(keycloak.refreshTokenParsed), keycloak.timeSkew);
        refreshTokenExpiredTimeoutId = setTimeout(() => {
            localStorage.removeItem('tokens');
            keycloak.logout();
        }, expiresIn * 1000);
    }
    
    const tokensString = JSON.stringify({
        idToken: keycloak.idToken,
        token: keycloak.token,
        refreshToken: keycloak.refreshToken
    });
    localStorage.setItem('tokens', tokensString);
}

keycloak.onAuthSuccess = () => {
    if (keycloak.onAuthRefreshSuccess) {
        keycloak.onAuthRefreshSuccess();
    }

    keycloak.loadUserProfile()
        .then((profile) => setUser(profile))
        .catch((error) => Sentry.captureException(error));
}

export default keycloak;